import { Container } from "typescript-ioc";
import { OneTrust } from "./OneTrust";

declare global {
    interface IOri {
        /**
         * Global API Gateway Module
         */
        oneTrust?: OneTrust;
    }
}

ori.oneTrust = Container.get(OneTrust);
